<template>
    <div class="option_catalogue">
        <vs-row>
            <vs-col vs-xs="12" vs-lg="6" class="p-3">
                <vx-card>
                    <h3>Information établissement</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Type" v-model="studio.society" />
                    </div>
                     <div class="vx-col w-full">
                        <vs-input class="w-full" label="Nom societé" v-model="studio.type" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Capital" v-model="studio.capital" type="number" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Adresse" v-model="studio.address" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Ville" v-model="studio.city" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Code postal" v-model="studio.code_postal" type="number" />
                    </div>
                </vx-card>
            </vs-col>
            <vs-col vs-xs="12" vs-lg="6" class="p-3">
                <vx-card>
                    <h3>Contact public</h3>
                    <hr/>
                    <br/>
                    <div class="vx-col w-full">
                        <vue-tel-input id="studiotelinput" class="w-full" label="Téléphone" v-model="studio.phone"></vue-tel-input>
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="Email" v-model="studio.email" />
                    </div>
                </vx-card>

                <br/>
                <vx-card>
                    <h3>Numéro société</h3>
                    <hr/>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="SIREN" v-model="studio.siren" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="RCS ville" v-model="studio.rcs_city" />
                    </div>
                    <div class="vx-col w-full">
                        <vs-input class="w-full" label="TVA numéro" v-model="studio.number_tva" />
                    </div>
                </vx-card>

            </vs-col>

        </vs-row>

        <br/>
        <vs-row>
            <vs-col vs-type="flex" vs-justify="space-between" vs-align="center" vs-xs="12">
                <div></div>
                <vs-button color="success" type="filled" v-on:click="update()"> Modifier </vs-button>
            </vs-col>
        </vs-row>
        
    </div>
</template>



<style lang="scss">
.w-full {
    padding: 0.1rem 0;
}
</style>

<script>

import autoRefresh from '@/assets/utils/autoRefresh'

import Studio from '@/database/models/studio'

export default {
    data(){
        return{
            studio:{
                type        : '',
                society     : '',
                address     : '',
                city        : '',
                code_postal : '',
                country     : '',
                capital     : '',
                siren       : '',
                rcs_city    : '',
                number_tva  : '',
                phone       : '',
                email       : '',
            },
        }
	},
	mounted:function()
	{
        //autoRefresh
        autoRefresh.SetCallback(()=>
        {
            //refresh
            Studio.getAllMemory((list_studio)=>
            {
                //find actual studio
                this.studio = JSON.parse(JSON.stringify( list_studio[ this.$srvApi.getStudio() ] ));

            })
        })
        autoRefresh.refresh()
    },
    watch:{
        $route (){
            
        }
    },
    methods:{
        setTimes() {
            switch(true) {
                case this.user_booking_add / 186400 > 1:
                    this.user_booking_add_unit = 'j'
                    this.addTime = this.user_booking_add / 186400
                    break
                case this.user_booking_add / 3600 > 1:
                    this.user_booking_add_unit = 'h'
                    this.addTime = this.user_booking_add / 3600
                    break
                default:
                    this.user_booking_add_unit = 'm'
                    this.addTime = this.user_booking_add / 60
            }
            switch(true) {
                case this.user_booking_del / 186400 > 1:
                    this.user_booking_del_unit = 'j'
                    this.delTime = this.user_booking_del / 186400
                    break
                case this.user_booking_del / 3600 > 1:
                    this.user_booking_del_unit = 'h'
                    this.delTime = this.user_booking_del / 3600
                    break
                default:
                    this.user_booking_del_unit = 'm'
                    this.delTime = this.user_booking_del / 60
            }
        },
        getTimeText(unit, time) {
            let status = `${time} `
            switch(unit) {
                case 'm':
                    status += 'minute'
                    break
                case 'h':
                    status += 'heure'
                    break
                case 'd':
                    status += 'jour'
                    break
            }
            if (time > 1) status += 's'
            return status
        },
        calcInSeconds(unit, time) {
            let timeBuffer = time
            switch(unit) {
                case 'm':
                    timeBuffer *= 60
                    break
                case 'h':
                    timeBuffer *= 60*60
                    break
                case 'd':
                    timeBuffer += 60*60*24
                    break
            }
            return timeBuffer
        },
		update(){
            //nettoyage vide
            let data = {}
            for( var i in this.studio)
            if( this.studio[i])
                data[i] = this.studio[i]

            //envoi mise a jour
            Studio.update( this.$srvApi.getStudio(), data )
            .then(()=>{
                alert('Modification enregistrée')
            })
            .catch(()=>{
                alert('Une erreur est survenue !')
            })
        },
    }
};

</script>